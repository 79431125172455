import { hostsConfig, turnToConfig } from 'init/config'

const widgetsHostUrl = process.env.widgetsHostUrl
interface IPagesConfig {
  [name: string]: {
    baseCssFilePath: string
    baseJsFilePath: string
    pageCssFilePath: string
    customCss?: string
    pageJsFilePath: { [locale: string]: string }
  }
}

function loadCss(filePath: string, id: string): void {
  const existingCssNode = document.getElementById(id) as HTMLLinkElement
  const cssHref = widgetsHostUrl + filePath
  if (existingCssNode) {
    if (existingCssNode.href === cssHref) {
      return
    }
    existingCssNode.id = id + '-previous'
  }
  const head = document.getElementsByTagName('head')[0]
  const  cssNode = document.createElement('link')
  cssNode.id = id
  cssNode.type = 'text/css'
  cssNode.rel = 'stylesheet'
  cssNode.href = cssHref
  if (existingCssNode) {
    cssNode.onload = () => {
      existingCssNode.remove()
    }
  }
  head.appendChild(cssNode)
}

function loadJs(srcPath: string, id: string) {
  const jsNode = document.createElement('script') as HTMLScriptElement
  jsNode.id = id
  jsNode.type = 'text/javascript'
  jsNode.src = widgetsHostUrl + srcPath
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(jsNode)
}

function loadPage(pagesConfig: IPagesConfig) {
  const pageFilesConfig = pagesConfig[turnToConfig.pageId]
  if (!pageFilesConfig) {
    // eslint-disable-next-line no-console
    console.warn(`TurnTo: Could not find configuration for pageId: ${turnToConfig.pageId}`)
    return
  }
  if (pageFilesConfig.pageCssFilePath) {
    loadCss(pageFilesConfig.pageCssFilePath, 'tt-page-css')
  }
  if (pageFilesConfig.customCss) {
    loadCss(pageFilesConfig.customCss, 'tt-custom-css')
  }
  loadJs(pageFilesConfig.baseJsFilePath, 'tt-base-js')
  if (!pageFilesConfig.pageJsFilePath[turnToConfig.locale]) {
    // eslint-disable-next-line no-console
    console.warn(`TurnTo: Could not find a page configuration for locale: ${turnToConfig.locale}`)
    return
  }
  loadJs(pageFilesConfig.pageJsFilePath[turnToConfig.locale], 'tt-page-js')
}

// define so "loadPage" can be assigned
if (!window['TurnTo']) {
  window['TurnTo'] = {}
}

if (!(typeof window.IntersectionObserver === 'function') || !(typeof window.Proxy === 'function')) {
  // fake "loadPage" so there isn't a console error when it is called with the Vue 3
  // page config appended to this TurnTo js file.
  window['TurnTo'].loadPage = () => {}
  loadJs('/' + hostsConfig.siteKey + '/ie/js/turnto.js', 'tt-ie-turnto-js')
} else {
  if (process.env.NODE_ENV === 'development') {
    // DO NOT REMOVE OR CHANGE THE loadPage LINE. The dev-pages-for-entry.js loader will replace
    // with a pages object that is derived from the entry points listed in webpack.config.dev.js
    // loadPage({...})
  }

  window['TurnTo'].loadPage = loadPage
}
