/**
 * Sets and exports the turnTo configuration options. Assumes that this is
 * called after the client sets `window.turnToConfig`.
 */
const fullTurnToConfig: IWindowTurnToConfig = window.turnToConfig || {}

/**
 * Holds the hosts configuration for the deployment.  While most all values are a host name,
 * there are some others that do not change and are not to be manually set by the client,
 * such as siteKey.
 * This file does allow for manual configuration for qa and dev builds. In fact, dev should
 * be overriden, qa does not need to be.
 */
interface IHostsConfig {
  protocol: string
  cdnHost: string
  originHost: string
  imageStoreBase: string
  moderationHost: string
  siteKey: string
  analyticsHost: string
}

let hostsConfig: IHostsConfig

if (process.env.NODE_ENV === 'development' || process.env.testProdBuild) {
  hostsConfig = {
    protocol: fullTurnToConfig.protocol || 'http',
    cdnHost: fullTurnToConfig.cdnHost || 'localhost:9998',
    originHost: fullTurnToConfig.originHost || 'localhost:9998',
    imageStoreBase: fullTurnToConfig.imageStoreBase || 'wac.edgecastcdn.net/001A39/qa/v5',
    moderationHost: fullTurnToConfig.moderationHost || 'dev.turntodev.com',
    siteKey: fullTurnToConfig.siteKey || 'DeaequNgimr7koRsite',
    analyticsHost: 'https://nqes4ybiuf.execute-api.us-east-1.amazonaws.com/dev/event'
  }
} else {
  if (process.env.forQa && process.env.region === 'us1') {
    hostsConfig = {
      protocol: fullTurnToConfig.protocol || 'https',
      cdnHost: fullTurnToConfig.cdnHost || 'qa-cdn-ws.turntodev.com',
      originHost: fullTurnToConfig.originHost || 'qa-ws.turntodev.com',
      imageStoreBase: fullTurnToConfig.imageStoreBase || 'wac.edgecastcdn.net/001A39/qa',
      moderationHost: fullTurnToConfig.moderationHost || 'qa.turntodev.com',
      siteKey: BUILD_SITE_KEY,
      analyticsHost: 'https://qa-webevents.turntodev.com/event'
    }
  } else if (process.env.forStaging && process.env.region === 'us1') {
    hostsConfig = {
      protocol: 'https',
      cdnHost: 'staging-cdn-ws.turntodev.com',
      originHost: 'staging-ws.turntodev.com',
      imageStoreBase: 'wac.edgecastcdn.net/001A39/staging',
      moderationHost: 'staging.turntodev.com',
      siteKey: BUILD_SITE_KEY,
      analyticsHost: 'https://staging-webevents.turntodev.com/event'
    }
  } else if (process.env.region === 'us1') {
    hostsConfig = {
      protocol: 'https',
      cdnHost: 'cdn-ws.turnto.com',
      originHost: 'ws.turnto.com',
      imageStoreBase: 'wac.edgecastcdn.net/001A39/prod',
      moderationHost: fullTurnToConfig.moderationHost || 'www.turnto.com',
      siteKey: BUILD_SITE_KEY,
      analyticsHost: 'https://we.turnto.com/event'
    }
  } else if (process.env.region === 'eu1') {
    hostsConfig = {
      protocol: 'https',
      cdnHost: 'cdn-ws.turnto.eu',
      originHost: 'ws.turnto.eu',
      imageStoreBase: 'wac.edgecastcdn.net/005E122/eu1/prod',
      moderationHost: fullTurnToConfig.moderationHost || 'www.turnto.eu',
      siteKey: BUILD_SITE_KEY,
      analyticsHost: 'https://we.turnto.eu/event'
    }
  }
}

// override hosts and imageStoreBase for Design Editor widget builds
if (process.env.forThemer &&
  !(process.env.NODE_ENV === 'development' || process.env.testProdBuild)) { // Design Editor
  if (process.env.forQa && process.env.region === 'us1') { // QA - Design Editor
    hostsConfig.cdnHost = 'qa-de.turntodev.com'
    hostsConfig.originHost = 'qa-de.turntodev.com'
    hostsConfig.imageStoreBase = 'wac.edgecastcdn.net/001A39/qa/v5'
  } else if (process.env.region === 'us1') { // Prod US - Design Editor
    hostsConfig.cdnHost = 'de.turnto.com'
    hostsConfig.originHost = 'de.turnto.com'
    hostsConfig.imageStoreBase = 'wac.edgecastcdn.net/001A39/qa/v5'
  } else if (process.env.region === 'eu1') { // Prod EU - Design Editor
    hostsConfig.cdnHost = 'de.turnto.eu'
    hostsConfig.originHost = 'de.turnto.eu'
    hostsConfig.imageStoreBase = 'wac.edgecastcdn.net/001A39/qa/v5'
  }
}

/**
 * making sure locale is set for case where not specified
 */
if (!fullTurnToConfig.locale) {
  fullTurnToConfig.locale = process.env.defaultLocale || 'en_US'
}

// this narrows the scope of turnToConfig to exclude the hostsConfig. Doing this to a
// typescript error will happen if trying to access a hostsConfig property like siteKey
const turnToConfig = fullTurnToConfig as ITurnToConfig

export { turnToConfig, hostsConfig }
